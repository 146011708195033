@import url("https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Sidebar Animation */
@keyframes slideIn {
    from {
        width: 220px;
    }

    to {
        width: 420px;
    }
}

@keyframes slideOut {
    from {
        width: 420px;
    }

    to {
        width: 200px;
    }
}

/* Add the animation class to transition between states */
/* .transform {
    animation-fill-mode: backwards;
} */

.sidebar {
    transition: width 0.3s ease-in-out;
}

.sidebarActive {
    width: 280px;
}

.sidebarUnactive {
    width: 80px;
}

/* Optional: Add media query for smaller screens */
@media (max-width: 700px) {

    /* Sidebar Animation */
    @keyframes slideIn {
        from {
            transform: translateX(-100%);
        }

        to {
            transform: translateX(100%);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(-100%);
        }
    }

    /* .transform {
        transform: translateX(-100%) !important;
    } */
    .sidebar {
        position: absolute;
        width: 250px;
        transition: transform 0.3s ease-in-out;
    }

    .sidebarActive {
        transform: translateX(0%);
    }

    .sidebarUnactive {
        transform: translateX(-100%);
    }
}




body {
    background-color: #EEEEF1;
    font-family: "Poppins", "sans-serif";
}


::-webkit-scrollbar {
    width: 3px;
    height: 8px;
}


:-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: #CFD5DA;
    border-radius: 6px;
}

.scrollbarHide::-webkit-scrollbar {
    display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.loginGrid {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
}

@media (max-width: 1000px) {

    .loginGrid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .loginGrid .hideME {
        display: none;
    }


}

.loginBg {
    background-image: url("./assets/images/l-b-01.jpg");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, .3);
}

.sideBox {
    background: url('./assets/images/sideBox.jpg') center center;
}

.chatParent {
    display: grid;
    grid-template-columns: 0.33fr 1fr;
    align-items: center;
}

@media (max-width: 1350px) {
    .chatParent {
        display: grid;
        grid-template-columns: 1fr;
    }
}


.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: rgba(128, 128, 128, 0.673);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 999;
    bottom: 130%;
    left: 50%;
    margin-left: -80px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    z-index: 999;
}

@media (max-width : 1000px) {
    .tooltiptext {
        display: none;
    }
}

.calenderGrid {
    display: grid;
    grid-template-columns: 0.35fr 1fr;
    gap: 2px;
}


@media (max-width:1000px) {
    .calenderGrid {
        display: grid;
        grid-template-columns: 1fr;
    }
}




.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
}

.input-time {
    background: transparent;
    color: #ffff;
    border: 1px solid #ffff;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background-color: rgb(196, 196, 196);
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* // background-color: #015377; */
    border: 1px solid #D54D59;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #D54D59;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #D54D59 !important;
    /* // border: 1px solid #0075FF; */
}

/* 
// input:focus+.slider {
//     box-shadow: 0 0 1px #2196f3;
// } */

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 15px;
    background: #84858c;
    border: 1px solid #84858c;
}

.slider.round:before {
    border-radius: 50%;
    /* // background-color: #FC472B; */
}